import React, { useState, useEffect, useContext, useRef } from 'react'
import ReadMoreReact from 'read-more-react'
import ImageGallery from 'react-image-gallery'
import Toaster from '../../Component/Toaster'
import FavouriteCheckbox from '../../Product/components/atoms/FavoriteCheckbox'
import { Link, useHistory, useParams } from 'react-router-dom'
import moment from 'moment-timezone'
import Bidhistory from '../../Component/Bidhistory'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useMediaQuery } from 'react-responsive'
import BuyerContext from '../../Product/context/buyer/buyerContext'
import AuthContext from '../../Product/context/auth/authContext'
import CartContext from '../../Product/context/cart/cartContext'
import ProductContext from '../../Product/context/product/productContext'
import AlertContext from '../../Product/context/alert/alertContext'
import StripeCardContext from '../../Product/context/stripe/card/cardContext'

import {
    currencyFormat,
    handleRedirectInternal,
    removeHTMLTags,
    mapData,
    getAdsImage,
} from '../../Product/common/components'
import Bidding from '../../Product/components/molecules/Bidding/BiddingItem'
import Biddingforwardandreverse from '../../Product/components/molecules/Bidding/biddingforwardandreverse'
import Timer from '../../Product/common/timer'
import {
    messageHandler,
    messageHandlerSingle,
    make_an_offer_update_socket,
    qtyHandleBuynowsingle,
    reverse_auction_item,
} from '../../Product/common/socketHandler'
import { socket, socketAuction } from '../../Product/common/socket'
import PropTypes from 'prop-types'
import './slider.css'
import { Button, Drawer, Tooltip } from '@material-ui/core'
import Loaders from '../../Product/components/molecules/Loaders'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import SecondaryButton from '../../Product/components/atoms/SecondaryButton'
import BidStatus from '../../Product/components/molecules/Bidding/BidStatus'
import publicIp from 'public-ip'
import MessagePlugin from '../../utils/CommonFunctionality/MessagePlugin'
import NoRecordsFound from '../../Product/components/atoms/NoRecordsFound'
import ReactImageMagnify from 'react-image-magnify'
import axios from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Qtyinput from '../../Product/common/Qtyinputcard'
import MagnifyImageGallery from '../MagnifyImageGallery'
import Share from './Share'
import { Rating } from '@material-ui/lab'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import LotListProduct from '../ProductCard/lotListProduct'
import Terms from '../AuctionTerms/terms'
import AskQuestion from './askQuestion'
function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`pv-tabpanel-${index}`}
            className="pv-tabpanel"
            aria-labelledby={`pv-tab-${index}`}
            {...other}
        >
            {value === index && <div>{children}</div>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `pv-tab-${index}`,
        'aria-controls': `pv-tabpanel-${index}`,
    }
}

const SlidePanel = ({
    type,
    selectedLot,
    handleClose,
    viewPage,
    getAllProducts,
    from,
    audio,
    enableZoomOnHover,
    is_registered,
    private_auction,
    currencyType = '',
}) => {
    const buyerContext = useContext(BuyerContext)
    const cartContext = useContext(CartContext)
    const alertContext = useContext(AlertContext)
    const isDesktop = useMediaQuery({
        query: '(min-width: 991px)',
    })
    const authContext = useContext(AuthContext)
    const { setAlert } = alertContext
    const { get_all_card_details, get_skyflow_cards } = useContext(StripeCardContext)
    const [subLotData, setSubLotData] = useState([])
    const {
        insertProductView,
        insert_make_proposels,
        getLotList,
        search_allauctionproducts,
        productLocalState,
        getEditAuction,
        edit_auction,
    } = useContext(ProductContext)

    const { user, isAuthenticated } = authContext
    const {
        buyer_allproducts,
        getAllBuyerProducts,
        product_view,
        getViewProduct,
        setSocketProduct,
        get_ship_station_amount,
        shipping_rate_calculation,
        clear_ship_station_amount,
    } = buyerContext
    const { addToCart, buyer_cartitems, getUserCart, removeFromCart, responseStatus } = cartContext
    const { id } = useParams()
    const [historyData, setHistoryData] = useState({})
    const [loading, setLoading] = useState(true)
    const [disabled, setDisabled] = useState(false)
    const [askQuestion, setAskQuestion] = useState(false)
    const [cards, setCards] = useState([])
    const [selectedProductImage, setSelectedProductImage] = useState([])
    const [selectedProduct, setSelectedProduct] = useState({})
    const [upcomming, setUpcomming] = useState(false)
    const [shipping_amount, setShipping_amount] = useState(0)
    const [anchorEl, setAnchorEl] = useState(null)
    const [homeDepot, setHomeDepot] = useState({
        price: 0,
        date: '',
        product_url: '',
    })
    const [ASIN, setASIN] = useState({
        price: 0,
        date: '',
        product_url: '',
    })
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    const [value, setValue] = React.useState(0)
    const [value1, setValue1] = React.useState(0)
    const [auctionData, setAuctionData] = React.useState([])
    const [catg, setCatg] = useState(
        new URLSearchParams(window.location.search.substring(1)).get('cat'),
    )

    const toggleAskQuestion = () => setAskQuestion(!askQuestion)

    const pvTabsChange = (event, newValue) => {
        setValue(newValue)
    }
    const pvTabsChange1 = (event, newValue) => {
        setValue1(newValue)
        if (parseInt(newValue) == 0 || parseInt(newValue) == 1) {
            getLotList(
                {
                    cat_id_multiple_io:
                        parseInt(newValue) == 1 || catg == 0 || catg == 1
                            ? ''
                            : selectedProduct.category_id,
                    page: 1,
                    lotof: parseInt(newValue) == 0 ? selectedProduct.lotof : '0',
                    new_order_io:
                        global.storeDetails?.theme == 19
                            ? parseInt(newValue) == 0
                                ? 5
                                : 13
                            : undefined,
                    limit: 5,
                    market_status: 'open',
                    need_bid_status: 'yes',
                    auction_io: 1,
                    id_not_in: selectedProduct.id,
                },

                'auctionsimilarview',
            )
        }
    }
    let history = useHistory()
    const oneYear = new Date().setFullYear(new Date().getFullYear() + 1)
    const insertLotCount = async () => {
        const ip = await publicIp.v4()
        insertProductView({ project_id: product_view?.results?.id, user_id: ip })
    }
    const [audio_loc] = useState(
        global.storeConfigration?.bidding_sound?.value == 1 && audio
            ? audio
            : global.storeConfigration?.bidding_sound?.value == 1
            ? new Audio('/audio/ding.mp3')
            : '',
    )
    useEffect(() => {
        let idValue = id
        if (idValue.includes('?')) {
            const value = idValue.split('?')
            idValue = value[0]
        }
        setLoading(true)
        getViewProduct({ product_id: idValue })
    }, [id])

    const bodyClass = document.body.classList == 'LotsAuction'

    useEffect(() => {
        if (selectedProduct.category_id) {
            if (global.storeDetails?.theme !== 19) {
                getLotList(
                    {
                        cat_id_multiple_io: selectedProduct.category_id,
                        page: 1,
                        new_order_io: 5,
                        lotof: 0,
                        limit: 5,
                        market_status: 'open',
                        auction_io: 1,
                        id_not_in: selectedProduct.id,
                        auction_city:
                            productLocalState.globalLocation != 'All'
                                ? [productLocalState.globalLocation]
                                : [],
                    },

                    'auctionsimilarview',
                )
            }
        }
    }, [selectedProduct])

    useEffect(() => {
        if (search_allauctionproducts.from === 'auctionsimilarview') {
            setSubLotData(search_allauctionproducts.results)
        }
    }, [search_allauctionproducts])

    useEffect(() => {
        // setLoading(true)
        if (product_view.results) {
            setSelectedProduct({
                ...product_view.biddetails,
                ...product_view.results,
                incrementamt: product_view.incrementamt,
            })
            if (product_view?.attachments.length) {
                product_view?.attachments.forEach(function (data) {
                    data['original'] =
                        product_view.results?.store_id === 0
                            ? process.env.REACT_APP_BASE_URL + 'uploads/product/' + data.src
                            : process.env.REACT_APP_PRODUCT_IMAGE_URL + data.src
                    data['thumbnail'] = `${
                        product_view.results?.store_id === 0
                            ? process.env.REACT_APP_BASE_URL + 'uploads/product/'
                            : process.env.REACT_APP_PRODUCT_IMAGE_URL
                    }${global.storeConfigration?.image_compression?.value == 1 ? 'thumb_' : ''}${
                        data.src
                    }`
                })
                setSelectedProductImage(product_view.attachments)
            } else {
                setSelectedProductImage([
                    {
                        original: global?.storeDetails?.logoValue,
                        thumbnail: global?.storeDetails?.logoValue,
                    },
                ])
            }

            if (parseInt(product_view.results.buynow) === 1) {
                getUserCart()
            }
            setShipping_amount(0)
            setTimeout(() => {
                setLoading(false)
            }, 1500)
            //insertLotCount()
        }
    }, [product_view.results])

    useEffect(() => {
        if (buyer_cartitems.results && selectedProduct?.id) {
            let position = _.findIndex(buyer_cartitems.results, {
                project_id: parseInt(selectedProduct.id),
            })

            position === -1 ? setDisabled(false) : setDisabled(true)
        }
    }, [buyer_cartitems.results, selectedProduct])

    useEffect(() => {
        if (
            selectedProduct.lotof !== '' &&
            selectedProduct.lotof !== undefined &&
            global.storeDetails.theme == 19
        ) {
            console.log(' lot of ', selectedProduct.lotof)
            getEditAuction({ auctionid: selectedProduct.lotof })
        }
    }, [selectedProduct])

    useEffect(() => {
        if (edit_auction.auction_details && global.storeDetails?.theme == 19) {
            if (edit_auction.auction_details) {
                setAuctionData(edit_auction.auction_details)
            }
        }
    }, [edit_auction])

    const handleShare = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClick = async (e) => {
        if (global?.storeConfigration?.guest_user_login?.value) {
            if (
                buyer_cartitems.results.find(
                    (val) => parseInt(val.product_id) === parseInt(selectedProduct.id),
                )
            ) {
                removeFromCart({ id: selectedProduct.id })
            } else {
                addToCart({ id: selectedProduct.id, qty: selectedProduct.qty })
                setDisabled(true)
            }
        } else {
            if (!cards.length && global?.storeConfigration?.credit_card_no_need?.value != 1) {
                setAlert('Add a card to buy!', 'error')
                return handleRedirectInternal(history, 'dashboard/cards')
            }
            if (
                buyer_cartitems.results.find(
                    (val) => parseInt(val.product_id) === parseInt(selectedProduct.id),
                )
            ) {
                removeFromCart({ id: selectedProduct.id })
            } else {
                addToCart({ id: selectedProduct.id, qty: selectedProduct.qty })
                setDisabled(true)
            }
        }
    }
    useEffect(() => {
        if (global.storeConfigration?.skyflow_integration?.value == 1) {
            if (get_skyflow_cards) {
                setCards(get_skyflow_cards)
            }
        } else {
            if (get_all_card_details) {
                setCards(get_all_card_details.records)
            }
        }
    }, [get_all_card_details, get_skyflow_cards])

    useEffect(() => {
        if (selectedProduct) {
            if (
                selectedProduct.hd_product_id &&
                selectedProduct.mprice &&
                selectedProduct.retailer_link
            ) {
                setHomeDepot({
                    price: selectedProduct.mprice,
                    date: '',
                    product_url: selectedProduct.retailer_link,
                })
            } else {
                setHomeDepot({
                    price: 0,
                    date: '',
                    product_url: '',
                })
            }
            if (
                selectedProduct.asin_no &&
                selectedProduct.mprice &&
                selectedProduct.retailer_link
            ) {
                setASIN({
                    price: selectedProduct.mprice,
                    product_url: selectedProduct.retailer_link,
                })
            } else {
                setASIN({
                    price: 0,
                    date: '',
                    product_url: '',
                })
            }
        }
    }, [selectedProduct])
    const viewProductRef = useRef(selectedProduct)
    const userRef = useRef(user)
    const viewProductRef2 = useRef(subLotData)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = selectedProduct
        userRef.current = user
        viewProductRef2.current = subLotData
    })
    const handler = (message, type) => {
        messageHandlerSingle(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setSelectedProduct,
            type,
        )
    }

    const handler2 = (message, type) => {
        messageHandler(
            message,
            viewProductRef2.current,
            userRef.current,
            setAlert,
            setSubLotData,
            type,
        )
    }

    const validationmake_formik = Yup.object({
        proposed_amount: Yup.number()
            .positive('Enter Valid amount')
            .integer('Enter Valid amount')
            .typeError('Enter Valid amount')
            .min(1, `Enter Valid amount`)
            .test('is-decimal', 'Cannot be a decimal', (value) =>
                value ? typeof value === 'number' && value % 1 === 0 : true,
            )
            .required('Enter Valid amount'),
    })

    const make_formik = useFormik({
        initialValues: {
            proposed_amount: '',
            project_id: '',
            user_id: '',
            seller_id: '',
            status: '',
        },
        validationSchema: validationmake_formik,
        onSubmit: async (values) => {
            make_formik.values.project_id = selectedProduct.id
            make_formik.values.user_id = user.id
            make_formik.values.seller_id = selectedProduct.user_id
            make_formik.values.status = 'Pending'
            insert_make_proposels(values)
            make_formik.values.proposed_amount = ''
        },
    })

    var make_an_offer = [
        {
            label: 'Make Offer',
            placeholder: 'Enter Price',
            type: 'number',
            class: 'col-sm-6 col-12 makeMyOffer',
            name: 'proposed_amount',
            formik: make_formik,
        },
    ]

    const make_an_offer_update_new = (message, type) => {
        make_an_offer_update_socket(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setSelectedProduct,
            type,
        )
    }

    const qtyupdatebuynow = (message, type) => {
        qtyHandleBuynowsingle(
            message,
            viewProductRef.current,
            userRef.current,
            setSelectedProduct,
            type,
        )
    }

    const multiseller_handler = (message, type) => {
        if (parseInt(viewProductRef.current.content_head5) == 0) {
            messageHandlerSingle(
                message,
                viewProductRef.current,
                userRef.current,
                setAlert,
                setSelectedProduct,
                type,
            )
        } else {
            if (message.type == 'reverse_auction' && message.status == 'success') {
                reverse_auction_item(
                    message,
                    viewProductRef.current,
                    userRef.current,
                    setAlert,
                    setSelectedProduct,
                    'single',
                )
            }
        }
    }

    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            data.id = data.pid

            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            if (global.pluginConfiguration?.reverse_auction?.enable) {
                multiseller_handler(data, 'bidAddtime')
            } else {
                handler(data, 'bidAddtime')
            }
        })

        socket.on('cancelbidemitted', (data) => {
            // handler(data, 'cancelbidemitted')
            getViewProduct({ product_id: data.project_id, socket: 0 })
        })
        // socket.on('proDetails', (data) => {
        //     console.log(data, 'dataaaaaaa ProDetails')
        //     if (data.user_id == userRef?.current?.id) {
        //         setSocketProduct({ data })
        //     }
        // })
        socketAuction.on('realclosedupdates', (data) => {
            data.id = data.pid

            handler(data, 'realclosedupdates')
        })
        socketAuction.on('bidAddtime', (data) => {
            if (global.pluginConfiguration?.reverse_auction?.enable) {
                multiseller_handler(data, 'bidAddtime')
            } else {
                handler(data, 'bidAddtime')
            }
        })
        socket.on('make_an_offer_update', (data) => {
            make_an_offer_update_new(data, 'make_an_offer')
        })
        socket.on('real_close_update_buynow', (data) => {
            qtyupdatebuynow(data, 'qtyHandleBuynowsingl')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                data.id = data.pid

                handler(data, 'realclosedupdates')
            })
            socket.off('proDetails', (data) => {
                setSocketProduct({ data })
            })
            socket.off('bidAddtime', (data) => {
                if (global.pluginConfiguration?.reverse_auction?.enable) {
                    multiseller_handler(data, 'bidAddtime')
                } else {
                    handler(data, 'bidAddtime')
                }
            })

            socket.off('cancelbidemitted', (data) => {
                handler(data, 'cancelbidemitted')
            })

            socketAuction.off('realclosedupdates', (data) => {
                data.id = data.pid

                handler(data, 'realclosedupdates')
            })
            socketAuction.off('bidAddtime', (data) => {
                if (global.pluginConfiguration?.reverse_auction?.enable) {
                    multiseller_handler(data, 'bidAddtime')
                } else {
                    handler(data, 'bidAddtime')
                }
            })
            socket.off('make_an_offer_update', (data) => {
                make_an_offer_update_new(data, 'make_an_offer')
            })
            socket.off('real_close_update_buynow', (data) => {
                qtyupdatebuynow(data, 'qtyHandleBuynowsingl')
            })
        }
    }, [])

    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            handler2(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler2(data, 'bidAddtime')
        })

        socket.on('buyer_assurance', (data) => {
            handler2(data, 'buyer_assurance')
        })

        socketAuction.on('bidAddtime', (data) => {
            handler2(data, 'bidAddtime')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler2(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler2(data, 'bidAddtime')
            })
            socket.off('buyer_assurance', (data) => {
                handler2(data, 'buyer_assurance')
            })

            socketAuction.off('bidAddtime', (data) => {
                handler2(data, 'bidAddtime')
            })
        }
    }, [])

    const sliderRef = useRef(null)
    const imgRef = useRef([])
    const [rotateValue, setRotateValue] = useState(0)
    // console.log(selectedProductImage, 'selectedProductImage')
    const RenderImage = () => {
        return (
            <img
                src={selectedProductImage[sliderRef?.current?.getCurrentIndex()]?.original}
                className="checkRotate"
                id={`imgRef_${sliderRef?.current?.getCurrentIndex()}`}
                onError={(e) => (e.target.src = `${global?.storeDetails?.logoValue}`)}
            />
        )
    }

    const rotateImage = (index) => {
        let newValue = rotateValue + 90
        setRotateValue(newValue)

        if (newValue >= 450) {
            setRotateValue(0)
        } else {
            setRotateValue(newValue)
        }
        document.getElementsByClassName('checkRotate')[
            sliderRef?.current?.getCurrentIndex()
        ].style.transform = `rotate(${rotateValue}deg)`
    }
    // const RenderImageZoom = () => {
    //     return (
    //         <>
    //             <Button onClick={rotateImage} className="rotateImage">
    //                 <span className="material-icons">rotate_right</span>
    //             </Button>
    //         </>
    //     )
    // }

    const RenderImageZoom = () => {
        return (
            <>
                <ReactImageMagnify
                    {...{
                        smallImage: {
                            alt: '',
                            isFluidWidth: true,
                            src: selectedProductImage[sliderRef?.current?.getCurrentIndex()]
                                ?.original,
                            enlargedImageClassName: 'enlargedImage',
                        },
                        largeImage: {
                            src: selectedProductImage[sliderRef?.current?.getCurrentIndex()]
                                ?.original,
                            width: 800,
                            height: 800,
                        },
                        className: 'hoverZoom',
                        enlargedImagePosition: 'over',
                        isHintEnabled: false,
                        // enlargedImageContainerClassName: 'customZoom',
                        enlargedImageStyle: { objectFit: 'cover' },
                    }}
                />
            </>
        )
    }

    const calculate_shipstation_amount = () => {
        var calculation_data = {
            weight: selectedProduct.weight,
            height: selectedProduct.height,
            length: selectedProduct.length,
            width: selectedProduct.width,
            toPostalCode: user.zip,
            fromPostalCode: selectedProduct.sell_zipcode ? selectedProduct.sell_zipcode : '60446',
            site_id: global.storeDetails?.site_id ? global.storeDetails.site_id : '',
            project_id: selectedProduct.id,
            toCountry: user.country,
        }
        get_ship_station_amount(calculation_data)
    }

    useEffect(() => {
        if (shipping_rate_calculation.message) {
            if (shipping_rate_calculation.message.shipping_status) {
                if (shipping_rate_calculation.message.shipping_status == 200) {
                    setShipping_amount(
                        shipping_rate_calculation.message.rate[0]?.shipmentCost
                            ? parseFloat(shipping_rate_calculation.message.rate[0].shipmentCost)
                            : '0',
                    )
                } else if (shipping_rate_calculation.message.shipping_status == 500) {
                    setAlert(
                        'Product dimensional or Zip Code incorrect.Please check your Zip Code or contact seller.',
                        'error',
                    )
                } else {
                    setAlert('Please Try Again Later!', 'error')
                }
            } else {
                setAlert('Please Try Again Later!', 'error')
            }
            clear_ship_station_amount()
        }
    }, [shipping_rate_calculation])

    const imageChangeInterval = global?.pluginConfiguration?.advertisement?.refresh_key * 60000 // Convert minutes to milliseconds

    const isMobile = useMediaQuery({
        query: '(max-width: 500px)',
    })

    const [prodAds, setProdAds] = useState({
        deskProdTop: '',
        deskProdMid: '',
        deskProdBottom: '',
        mobProdTop: '',
        mobProdMid: '',
        mobProdBottom: '',
    })

    const [activeProdAdsIndex, setActiveProdAdsIndex] = useState({
        deskProdTop: 0,
        deskProdMid: 0,
        deskProdBottom: 0,
        mobProdTop: 0,
        mobProdMid: 0,
        mobProdBottom: 0,
    })

    function updateRandomIndex(array) {
        const randomIndex = Math.floor(Math.random() * array.length)
        return randomIndex
    }

    useEffect(() => {
        setProdAds((prevAucAds) => ({
            deskProdTop: getAdsImage(1, 3, 1),
            deskProdMid: getAdsImage(1, 3, 2),
            deskProdBottom: getAdsImage(1, 3, 5),
            mobProdTop: getAdsImage(2, 3, 1),
            mobProdMid: getAdsImage(2, 3, 2),
            mobProdBottom: getAdsImage(2, 3, 5),
        }))
        pvTabsChange1('e', 1)
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveProdAdsIndex((prevActiveIndex) => ({
                deskProdTop: updateRandomIndex(prodAds?.deskProdTop),
                deskProdMid: updateRandomIndex(prodAds?.deskProdMid),
                deskProdBottom: updateRandomIndex(prodAds?.deskProdBottom),
                mobProdTop: updateRandomIndex(prodAds?.mobProdTop),
                mobProdMid: updateRandomIndex(prodAds?.mobProdMid),
                mobProdBottom: updateRandomIndex(prodAds?.mobProdBottom),
            }))
        }, imageChangeInterval)
        return () => clearInterval(interval)
    }, [prodAds])

    const render = (
        <>
            {loading ? (
                <div className="sliderLoader">
                    <Loaders name="product_view" isLoading={loading} loop={1} />
                </div>
            ) : (
                <>
                    {selectedProduct?.id ? (
                        <div className="product-view-wrapper">
                            {global?.pluginConfiguration?.advertisement?.enable == 1 ? (
                                <div className="siteAds adsProd">
                                    {isMobile
                                        ? prodAds?.mobProdTop[activeProdAdsIndex?.mobProdTop]
                                        : prodAds?.deskProdTop[activeProdAdsIndex?.deskProdTop]}
                                </div>
                            ) : null}
                            <div className="media-body position-relative">
                                {!viewPage && (
                                    <img
                                        src="/image/close.png"
                                        onClick={() => {
                                            handleClose()
                                        }}
                                        className="close-img"
                                        height="35"
                                        width="35"
                                    />
                                )}

                                <div className="pvr-top mb-4 pvr-share-wrap">
                                    <Button
                                        onClick={() => {
                                            history.goBack()
                                            window.scrollTo(0, 0)
                                        }}
                                        className="moveBack"
                                    >
                                        <span className="material-icons mr-3">arrow_back</span>
                                        {'back'}
                                    </Button>
                                    <div className="pvr-top-right">
                                        <Share
                                            anchorEl={anchorEl}
                                            handleClose={() => setAnchorEl(false)}
                                            handleClick={handleShare}
                                            title={selectedProduct.title}
                                            selectedProduct={selectedProduct}
                                        />
                                        <div
                                            className="printViewBtn"
                                            onClick={() => window.print()}
                                        >
                                            <img
                                                src="/lots-images/printer-small.svg"
                                                alt=""
                                                className="mr-2"
                                            />
                                            Print Page
                                        </div>
                                    </div>
                                </div>
                                <div className="product-view-container">
                                    <div className="product-view-left">
                                        {global?.storeConfigration?.magnify_image_gallery?.value ==
                                        1 ? (
                                            <MagnifyImageGallery images={selectedProductImage} />
                                        ) : (
                                            <ImageGallery
                                                items={selectedProductImage}
                                                thumbnailPosition="left"
                                                showNav={false}
                                                showBullets={false}
                                                showFullscreenButton={true}
                                                showPlayButton={false}
                                                onErrorImageURL={`${global?.storeDetails?.logoValue}`}
                                                originalClass="slidImg img-fluid"
                                                // renderCustomControls={RenderImageZoom}
                                                renderItem={RenderImage}
                                                ref={sliderRef}
                                            />
                                        )}
                                    </div>

                                    <div className="product-view-right">
                                        {isAuthenticated &&
                                        global?.storeConfigration?.bid_box_bidding_message?.value !=
                                            1 ? (
                                            selectedProduct.auction ? (
                                                type === 'buynow' ? null : (
                                                    <BidStatus
                                                        bidTopStatus={
                                                            selectedProduct.bids !== 0
                                                                ? selectedProduct.bidtopstatus
                                                                : ''
                                                        }
                                                    />
                                                )
                                            ) : null
                                        ) : null}

                                        <div className="pvr-top">
                                            {global?.storeConfigration?.hide_lot_id?.value == 1 ||
                                            global?.storeDetails?.theme == 19 ? null : (
                                                <div className="pvr-top-left">
                                                    <div className="pv-lot-box">
                                                        Lot#{' '}
                                                        {global?.storeConfigration
                                                            ?.lot_sequence_order?.value == 1
                                                            ? selectedProduct.lot_number
                                                            : global.storeConfigration?.own_seller
                                                                  ?.value == '1'
                                                            ? selectedProduct.id
                                                            : selectedProduct.lot_number
                                                            ? selectedProduct.lot_number
                                                            : ''}
                                                    </div>
                                                </div>
                                            )}
                                            {isAuthenticated ? (
                                                <div className="pvr-top-right w-100">
                                                    <a
                                                        className="ml-auto ask-question"
                                                        onClick={toggleAskQuestion}
                                                    >
                                                        <img
                                                            src="/lots-images/Question.svg"
                                                            alt=""
                                                            className="mr-2"
                                                        />
                                                        Ask a question
                                                    </a>
                                                    <div className="fav-wrap">
                                                        <FavouriteCheckbox
                                                            watchlisted={selectedProduct.wlistpop}
                                                            data={selectedProduct}
                                                            project_id={selectedProduct.id}
                                                            getAllProducts={getAllProducts}
                                                            setSelectedProduct={setSelectedProduct}
                                                            from={'card'}
                                                        />
                                                        <span>Add to watchlist</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                        <div>
                                            {homeDepot.price && homeDepot.product_url ? (
                                                <h5 className="lotNo">
                                                    <a
                                                        href={homeDepot.product_url}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Home Depot Retail Price -{' '}
                                                        {currencyFormat(
                                                            parseFloat(homeDepot.price).toFixed(2),
                                                        )}{' '}
                                                        {/* - {homeDepot.date} */}
                                                    </a>
                                                </h5>
                                            ) : null}
                                            {/* {ASIN.price && ASIN.product_url ? (
                                                <h5 className="lotNo">
                                                    <a
                                                        href={ASIN.product_url}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Amazon Retail Price -{' '}
                                                        {currencyFormat(
                                                            parseFloat(ASIN.price).toFixed(2),
                                                        )}{' '}
                                                    </a>
                                                </h5>
                                            ) : null} */}

                                            {global.pluginConfiguration?.remote_seller?.enable ? (
                                                <div className="pv-con">
                                                    <span>Posted By:</span>
                                                    {selectedProduct.username}
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>

                                        <h3
                                            className="plTitle"
                                            dangerouslySetInnerHTML={{
                                                __html: selectedProduct.title,
                                            }}
                                        />
                                        <div className="pv-rating-wrap d-flex justify-content-between">
                                            {/* <div className="pv-qrating">
                                                <div>Quality Rating</div>
                                                <Rating
                                                    name="read-only"
                                                    value={
                                                        global.storeDetails.theme == 19
                                                            ? selectedProduct.auctionnotes
                                                                  .trim()
                                                                  .toLowerCase()
                                                                  .includes('rating 5')
                                                                ? 5
                                                                : selectedProduct.auctionnotes
                                                                      .trim()
                                                                      .toLowerCase()
                                                                      .includes('rating 4')
                                                                ? 4
                                                                : selectedProduct.auctionnotes
                                                                      .trim()
                                                                      .toLowerCase()
                                                                      .includes('rating 3')
                                                                ? 3
                                                                : selectedProduct.auctionnotes
                                                                      .trim()
                                                                      .toLowerCase()
                                                                      .includes('rating 2')
                                                                ? 2
                                                                : 1
                                                            : 4
                                                    }
                                                    precision={0.5}
                                                    readOnly
                                                />
                                            </div> */}
                                            {selectedProduct.auction == 1 ? (
                                                <div className="timer-wrapper">
                                                    <div>
                                                        <img
                                                            src="/lots-images/timer-red.svg"
                                                            alt=""
                                                            className="lv-timer"
                                                        />
                                                    </div>
                                                    {selectedProduct.market_status != 'open' ||
                                                    selectedProduct.productClosed ? (
                                                        'Closed'
                                                    ) : selectedProduct.extend ||
                                                      selectedProduct.agent_id ? (
                                                        <span className="tmm">
                                                            <Timer
                                                                date_added={
                                                                    selectedProduct.date_added
                                                                }
                                                                date_closed={
                                                                    moment(
                                                                        selectedProduct.date_closed,
                                                                    ).isValid()
                                                                        ? selectedProduct.date_closed
                                                                        : oneYear
                                                                }
                                                                withText={1}
                                                                endText={'Ends in' + ':'}
                                                                startText={'Starts in' + ':'}
                                                                setUpcomming={setUpcomming}
                                                                audio={audio_loc}
                                                            ></Timer>
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            <Timer
                                                                date_added={
                                                                    selectedProduct.date_added
                                                                }
                                                                date_closed={
                                                                    moment(
                                                                        selectedProduct.date_closed,
                                                                    ).isValid()
                                                                        ? selectedProduct.date_closed
                                                                        : oneYear
                                                                }
                                                                withText={1}
                                                                endText={'Ends in' + ':'}
                                                                startText={'Starts in' + ':'}
                                                                setUpcomming={setUpcomming}
                                                                audio={audio_loc}
                                                            ></Timer>
                                                        </span>
                                                    )}
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        {/*<div className="pv-con">
                                            <span>Description:</span>
                                        </div>
                                        <p
                                            key={selectedProduct.id}
                                            dangerouslySetInnerHTML={{
                                                __html: selectedProduct.description,
                                            }}
                                        ></p>
                                        */}
                                        {/*selectedProduct.location &&
                                        !selectedProduct?.location?.includes('[') ? (
                                            <div className="d-flex align-items-baseline justify-content-start">
                                                <div className="pv-con">
                                                    <span>Warehouse Location:</span>
                                                </div>
                                                <p className="">{selectedProduct.location}</p>
                                            </div>
                                        ) : null */}
                                        {/*<div className="d-flex align-items-baseline justify-content-start">
                                            <div className="pv-con">
                                                <span>Condition:</span>
                                            </div>
                                            {global?.storeConfigration?.condition_field?.value ? (
                                                <p>
                                                    {selectedProduct.extra_fields
                                                        ? JSON.parse(selectedProduct.extra_fields)
                                                              .condition_field
                                                        : '-'}
                                                </p>
                                            ) : global?.condition_data?.find(
                                                  (val) =>
                                                      val.condition == selectedProduct.auctionnotes,
                                              )?.content ? (
                                                <>
                                                    <Tooltip
                                                        title={
                                                            global?.condition_data?.find(
                                                                (val) =>
                                                                    val.condition ==
                                                                    selectedProduct.auctionnotes,
                                                            )?.content
                                                        }
                                                        arrow
                                                        placement="top-start"
                                                        opensOn="Hover"
                                                        className="tooling"
                                                    >
                                                        <span>i</span>
                                                    </Tooltip>
                                                    <p
                                                        // className="ml-4"
                                                        dangerouslySetInnerHTML={{
                                                            __html: selectedProduct.auctionnotes,
                                                        }}
                                                    ></p>
                                                </>
                                            ) : (
                                                <p
                                                    // className="ml-4"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            selectedProduct.auctionnotes ||
                                                            selectedProduct.conditionunique,
                                                    }}
                                                ></p>
                                            )}
                                        </div>
                                        */}
                                        {/*global?.storeConfigration?.need_transferable?.value ==
                                        1 ? (
                                            <div className="d-flex align-items-baseline justify-content-start">
                                                <div className="pv-con">
                                                    <span>Transferable:</span>
                                                </div>
                                                {selectedProduct.transferable ? (
                                                    <Tooltip
                                                        title={
                                                            selectedProduct.transferable == 'Yes'
                                                                ? 'This item can be transfered to a different warehouse location for pickup.'
                                                                : selectedProduct.transferable ==
                                                                  'No'
                                                                ? 'This item must be picked up from its original location.'
                                                                : ''
                                                        }
                                                        arrow
                                                        placement="top-start"
                                                        open={showTooltip}
                                                        onOpen={() => setShowTooltip(true)}
                                                        onClose={() => setShowTooltip(false)}
                                                        opensOn="Click"
                                                        className="tooling"
                                                    >
                                                        <span
                                                            onClick={() =>
                                                                setShowTooltip(!showTooltip)
                                                            }
                                                        >
                                                            i
                                                        </span>
                                                    </Tooltip>
                                                ) : (
                                                    ''
                                                )}
                                                <p>
                                                    {selectedProduct.transferable
                                                        ? selectedProduct.transferable
                                                        : ' - '}
                                                </p>
                                            </div>
                                                    ) : null*/}
                                        {/*global.pluginConfiguration?.most_viewed?.enable == 1 &&
                                        selectedProduct.viwers ? (
                                            <>
                                                {' '}
                                                <div className="pv-con">
                                                    <span>Viewers:</span>
                                                </div>
                                                <p>{selectedProduct.viwers}</p>
                                            </>
                                        ) : null*/}
                                        {/*selectedProduct.mprice ? (
                                            <>
                                                <div className="pv-con">
                                                    <span>Retail Price</span>
                                                </div>
                                                <p>
                                                    {currencyFormat(
                                                        parseFloat(selectedProduct.mprice).toFixed(
                                                            2,
                                                        ),
                                                    )}
                                                </p>
                                            </>
                                                        ) : null*/}
                                        <div className="pv-bid-details">
                                            {selectedProduct.lot_number ? (
                                                <div className="pv-con">
                                                    <div>LOT #: </div>
                                                    <span className="d-flex align-items-center">
                                                        <span className="prcTxtVal">
                                                            {selectedProduct.lot_number}
                                                        </span>
                                                    </span>
                                                </div>
                                            ) : null}
                                            {selectedProduct.auction == 1 ? (
                                                <div className="pv-con">
                                                    <div>Current Price:</div>
                                                    <div className="bids-wrap">
                                                        <span>
                                                            {currencyFormat(
                                                                parseFloat(
                                                                    selectedProduct.wprice,
                                                                ).toFixed(2),
                                                            )}
                                                        </span>
                                                        {type !== 'buynow' &&
                                                        selectedProduct.auction ? (
                                                            <div className="pv-bids-details">
                                                                (<div>Bids: </div>
                                                                <a
                                                                    className="tb-link"
                                                                    onClick={() =>
                                                                        selectedProduct.bids &&
                                                                        selectedProduct.bids !==
                                                                            0 &&
                                                                        !selectedProduct.is_past &&
                                                                        setHistoryData({
                                                                            product_id:
                                                                                selectedProduct.id,
                                                                            end: moment(
                                                                                selectedProduct.date_closed,
                                                                            ).isValid()
                                                                                ? selectedProduct.date_closed
                                                                                : oneYear,
                                                                        })
                                                                    }
                                                                >
                                                                    {selectedProduct.bids
                                                                        ? selectedProduct.bids
                                                                        : 0}
                                                                </a>
                                                                )
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>

                                                    {selectedProduct.bids &&
                                                    selectedProduct.rprice &&
                                                    parseFloat(selectedProduct.rprice) > 0 ? (
                                                        parseFloat(selectedProduct?.wprice) >=
                                                        parseFloat(selectedProduct?.rprice) ? (
                                                            <div>(Reserve has been met)</div>
                                                        ) : (
                                                            <div>
                                                                (Reserve has not been met yet)
                                                            </div>
                                                        )
                                                    ) : null}
                                                </div>
                                            ) : (
                                                <>
                                                    <div className="pv-con">
                                                        <div>Buy now Price:</div>
                                                        {currencyFormat(
                                                            parseFloat(
                                                                selectedProduct.bprice,
                                                            ).toFixed(2),
                                                        )}
                                                    </div>
                                                    {global?.storeConfigration?.buy_now_with_qty
                                                        ?.value == 1 &&
                                                    global?.storeConfigration?.unique_slot4
                                                        ?.value != 1 ? (
                                                        <div className="pv-con">
                                                            <div>Available Qty:</div>
                                                            {selectedProduct.qty}
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {global?.storeConfigration?.qty_input_slider
                                                        ?.value == 1 ? (
                                                        <div>
                                                            <Qtyinput
                                                                type={'slider'}
                                                                total_qty={selectedProduct.qty}
                                                                qty={'1'}
                                                                product_id={selectedProduct.id}
                                                                disabled={disabled}
                                                                history={history}
                                                            />
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {global?.storeConfigration?.unique_slot4
                                                        ?.value == 1 ? (
                                                        <>
                                                            <div className="pv-con">
                                                                <span>Total Qty:</span>
                                                                {selectedProduct.qty +
                                                                    selectedProduct.sold}
                                                            </div>
                                                            <div className="pv-con">
                                                                <span>Available Qty:</span>
                                                                {selectedProduct.qty}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                            )}
                                            {selectedProduct.next_bid ? (
                                                <div className="pv-con">
                                                    <div>Min Bid: </div>
                                                    <div className="d-flex align-items-center">
                                                        <span className="prcTxtVal">
                                                            {currencyFormat(
                                                                parseFloat(
                                                                    selectedProduct.next_bid,
                                                                ).toFixed(2),
                                                                currencyType,
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                            {selectedProduct.incrementamt &&
                                            selectedProduct.auction ? (
                                                <div className="pv-con">
                                                    <div>Bid Increment: </div>
                                                    <span className="d-flex align-items-center">
                                                        <span className="prcTxtVal">
                                                            {currencyFormat(
                                                                parseFloat(
                                                                    selectedProduct.incrementamt,
                                                                ).toFixed(2),
                                                                currencyType,
                                                            )}
                                                        </span>
                                                    </span>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                            <div className="pv-con">
                                                <div>MSRP: </div>
                                                <span className="d-flex align-items-center">
                                                    <span className="prcTxtVal">
                                                        {currencyFormat(
                                                            parseFloat(
                                                                selectedProduct.mprice,
                                                            ).toFixed(2),
                                                            currencyType,
                                                        )}
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-start align-items-start w-100 flex-wrap loaCategory">
                                            {selectedProduct.auctionnotes.length
                                                ? selectedProduct.auctionnotes !=
                                                      (null || undefined) &&
                                                  selectedProduct.auctionnotes
                                                      ?.split(',')
                                                      ?.map((val, index) => (
                                                          <div
                                                              className="conditionLiq loa-condition"
                                                              key={index}
                                                          >
                                                              <p className="m-0 cursorDecoy">
                                                                  {val}
                                                              </p>
                                                          </div>
                                                      ))
                                                : null}
                                        </div>
                                        {parseInt(selectedProduct.hasshipping) &&
                                        global.pluginConfiguration?.shipping?.enable &&
                                        isAuthenticated ? (
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <div className="pv-con">
                                                        <p>
                                                            Rates based on Shipping Address on
                                                            profile. Update
                                                            <Link to="/dashboard/profile">
                                                                here
                                                            </Link>
                                                        </p>
                                                        {shipping_amount ? (
                                                            <>
                                                                {/* <div className="pv-con"> */}
                                                                <span>Shipping Amount</span>

                                                                {currencyFormat(
                                                                    parseFloat(
                                                                        shipping_amount,
                                                                    ).toFixed(2),
                                                                )}
                                                                {/* </div> */}
                                                            </>
                                                        ) : (
                                                            <PrimaryButton
                                                                label="Calculate Shipping"
                                                                btnSize="small"
                                                                onClick={() =>
                                                                    calculate_shipstation_amount()
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        {global?.pluginConfiguration?.advertisement?.enable == 1 ? (
                                            <div className="siteAds adsProd">
                                                {isMobile
                                                    ? prodAds?.mobProdMid[
                                                          activeProdAdsIndex?.mobProdMid
                                                      ]
                                                    : prodAds?.deskProdMid[
                                                          activeProdAdsIndex?.deskProdMid
                                                      ]}
                                            </div>
                                        ) : null}
                                        <div className="row bids-row">
                                            <div className="col-lg-6">
                                                <div className="media pl-media">
                                                    <div className="media-left">
                                                        <img src="/lots-images/pickup.svg" alt="" />
                                                    </div>
                                                    <div className="media-body">
                                                        <h5>Pick Up Location:</h5>
                                                        <address>
                                                            4801W University Dr Suite 104 <br />
                                                            Denton, TX 76207
                                                        </address>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                {selectedProduct.market_status != 'open' ||
                                                selectedProduct.productClosed ? (
                                                    <b>Lot Closed</b>
                                                ) : (
                                                    <div
                                                        className={`sl-pbids slidView lots-bidding-view ${
                                                            !isAuthenticated && 'notLogged'
                                                        }`}
                                                    >
                                                        {bodyClass ? (
                                                            <p className="mb-2">
                                                                Enter your highest bid
                                                            </p>
                                                        ) : null}
                                                        {isAuthenticated &&
                                                        global?.storeConfigration
                                                            ?.bid_box_bidding_message?.value ==
                                                            1 ? (
                                                            selectedProduct.auction ? (
                                                                type === 'buynow' ? null : (
                                                                    <BidStatus
                                                                        bidTopStatus={
                                                                            selectedProduct.bids !==
                                                                            0
                                                                                ? selectedProduct.bidtopstatus
                                                                                : ''
                                                                        }
                                                                    />
                                                                )
                                                            ) : null
                                                        ) : null}
                                                        {isAuthenticated ||
                                                        global?.storeConfigration?.guest_user_login
                                                            ?.value ? (
                                                            <>
                                                                {selectedProduct.auction &&
                                                                selectedProduct?.user_id ==
                                                                    user?.id ? (
                                                                    <p>
                                                                        You cannot bid your own
                                                                        product!
                                                                    </p>
                                                                ) : global.pluginConfiguration
                                                                      ?.reverse_auction?.enable &&
                                                                  selectedProduct.content_head5 ==
                                                                      1 ? (
                                                                    <Biddingforwardandreverse
                                                                        theme="multiseller"
                                                                        data={selectedProduct}
                                                                        hard="1"
                                                                        is_registered={
                                                                            is_registered
                                                                        }
                                                                        private_auction={
                                                                            private_auction
                                                                        }
                                                                    />
                                                                ) : global.pluginConfiguration
                                                                      ?.make_an_offer?.enable ? (
                                                                    <>
                                                                        {selectedProduct?.make_an_offer_io &&
                                                                        upcomming ? (
                                                                            ''
                                                                        ) : (
                                                                            <>
                                                                                {' '}
                                                                                {selectedProduct.auction ? (
                                                                                    type ===
                                                                                        'buynow' &&
                                                                                    !selectedProduct.auction ? null : (
                                                                                        <>
                                                                                            {global
                                                                                                .storeConfigration
                                                                                                ?.hard_bid
                                                                                                ?.value ==
                                                                                                '1' && (
                                                                                                <>
                                                                                                    {/*<p className="slidViewBtnLabel">
                                                                                                        <span className="material-icons">
                                                                                                            info
                                                                                                        </span>
                                                                                                        Hard
                                                                                                        Bid
                                                                                                        -
                                                                                                        Bid
                                                                                                        on
                                                                                                        the
                                                                                                        current
                                                                                                        price
                                                                                                    </p> */}
                                                                                                    <Bidding
                                                                                                        data={
                                                                                                            selectedProduct
                                                                                                        }
                                                                                                        hard="1"
                                                                                                        audio={
                                                                                                            audio_loc
                                                                                                        }
                                                                                                    />
                                                                                                </>
                                                                                            )}
                                                                                        </>
                                                                                    )
                                                                                ) : null}
                                                                                {selectedProduct.auction ? (
                                                                                    type ===
                                                                                        'buynow' &&
                                                                                    !selectedProduct.auction ? null : (
                                                                                        <>
                                                                                            {global
                                                                                                .storeConfigration
                                                                                                ?.proxy_bid
                                                                                                ?.value ==
                                                                                                '1' && (
                                                                                                <>
                                                                                                    {/*<p className="slidViewBtnLabel">
                                                                                                        <span className="material-icons">
                                                                                                            info
                                                                                                        </span>
                                                                                                        Proxy
                                                                                                        Bid
                                                                                                        -
                                                                                                        System
                                                                                                        will
                                                                                                        auto
                                                                                                        bid
                                                                                                        upto
                                                                                                        the
                                                                                                        amount
                                                                                                        entered
                                                                                                    </p>*/}
                                                                                                    <Bidding
                                                                                                        data={
                                                                                                            selectedProduct
                                                                                                        }
                                                                                                        hard="0"
                                                                                                        audio={
                                                                                                            audio_loc
                                                                                                        }
                                                                                                    />
                                                                                                </>
                                                                                            )}
                                                                                        </>
                                                                                    )
                                                                                ) : null}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {' '}
                                                                        {selectedProduct.auction ? (
                                                                            type === 'buynow' &&
                                                                            !selectedProduct.auction ? null : (
                                                                                <>
                                                                                    {global
                                                                                        .storeConfigration
                                                                                        ?.hard_bid
                                                                                        ?.value ==
                                                                                        '1' && (
                                                                                        <>
                                                                                            {/*<p className="slidViewBtnLabel">
                                                                                                <span className="material-icons">
                                                                                                    info
                                                                                                </span>
                                                                                                Hard
                                                                                                Bid
                                                                                                -
                                                                                                Bid
                                                                                                on
                                                                                                the
                                                                                                current
                                                                                                price
                                                                                            </p>*/}
                                                                                            <Bidding
                                                                                                data={
                                                                                                    selectedProduct
                                                                                                }
                                                                                                hard="0"
                                                                                                audio={
                                                                                                    audio_loc
                                                                                                }
                                                                                                shortText="Submit Bid"
                                                                                            />
                                                                                            <Bidding
                                                                                                data={
                                                                                                    selectedProduct
                                                                                                }
                                                                                                hideBid={
                                                                                                    1
                                                                                                }
                                                                                                hard="1"
                                                                                                audio={
                                                                                                    audio_loc
                                                                                                }
                                                                                                shortText="Submit Your Bid"
                                                                                                enableNextBid={
                                                                                                    true
                                                                                                }
                                                                                                nextbidText={`Quick Bid ${
                                                                                                    selectedProduct?.next_bid
                                                                                                        ? currencyFormat(
                                                                                                              parseFloat(
                                                                                                                  selectedProduct?.next_bid
                                                                                                                      ? selectedProduct?.next_bid
                                                                                                                      : 0,
                                                                                                              ).toFixed(
                                                                                                                  2,
                                                                                                              ),
                                                                                                          )
                                                                                                        : ''
                                                                                                }`}
                                                                                            />
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            )
                                                                        ) : null}
                                                                        {/*selectedProduct.auction ? (
                                                                            type === 'buynow' &&
                                                                            !selectedProduct.auction ? null : (
                                                                                <>
                                                                                    {global
                                                                                        .storeConfigration
                                                                                        ?.proxy_bid
                                                                                        ?.value ==
                                                                                        '1' && (
                                                                                        <>
                                                                                            {/*<p className="slidViewBtnLabel">
                                                                                                <span className="material-icons">
                                                                                                    info
                                                                                                </span>
                                                                                                Proxy
                                                                                                Bid
                                                                                                -
                                                                                                System
                                                                                                will
                                                                                                auto
                                                                                                bid
                                                                                                upto
                                                                                                the
                                                                                                amount
                                                                                                entered
                                                                                            </p> 
                                                                                            <Bidding
                                                                                                data={
                                                                                                    selectedProduct
                                                                                                }
                                                                                                hard="0"
                                                                                                audio={
                                                                                                    audio_loc
                                                                                                }
                                                                                            />
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            )
                                                                        ) : null */}
                                                                    </>
                                                                )}
                                                                {global?.storeConfigration
                                                                    ?.qty_input_slider?.value !=
                                                                1 ? (
                                                                    <>
                                                                        {selectedProduct.buynow &&
                                                                        selectedProduct?.user_id ==
                                                                            user?.id ? (
                                                                            <p>
                                                                                You cannot buy your
                                                                                own product!
                                                                            </p>
                                                                        ) : (
                                                                            <>
                                                                                {selectedProduct.buynow ? (
                                                                                    selectedProduct.wprice >=
                                                                                    selectedProduct.bprice ? null : (
                                                                                        <>
                                                                                            {user?.id ==
                                                                                            selectedProduct.user_id ? null : (
                                                                                                <div className="">
                                                                                                    <PrimaryButton
                                                                                                        onClick={
                                                                                                            handleClick
                                                                                                        }
                                                                                                        disabled={
                                                                                                            user?.status ==
                                                                                                            'suspended'
                                                                                                        }
                                                                                                    >
                                                                                                        {disabled
                                                                                                            ? 'Remove from cart'
                                                                                                            : `Buy now for ${currencyFormat(
                                                                                                                  parseFloat(
                                                                                                                      selectedProduct.bprice,
                                                                                                                  ).toFixed(
                                                                                                                      2,
                                                                                                                  ),
                                                                                                              )}`}
                                                                                                    </PrimaryButton>
                                                                                                </div>
                                                                                            )}
                                                                                            <br />
                                                                                        </>
                                                                                    )
                                                                                ) : null}

                                                                                {global
                                                                                    .pluginConfiguration
                                                                                    ?.make_an_offer
                                                                                    ?.enable &&
                                                                                selectedProduct?.make_an_offer_io &&
                                                                                selectedProduct?.buynow &&
                                                                                !selectedProduct?.auction &&
                                                                                selectedProduct?.user_id !=
                                                                                    user?.id ? (
                                                                                    <div className="row">
                                                                                        {mapData(
                                                                                            make_an_offer,
                                                                                        )}
                                                                                        <div className="col-sm-6 col-12">
                                                                                            <PrimaryButton
                                                                                                onClick={(
                                                                                                    e,
                                                                                                ) =>
                                                                                                    selectedProduct.market_status ==
                                                                                                    'open'
                                                                                                        ? make_formik.handleSubmit()
                                                                                                        : ''
                                                                                                }
                                                                                                disabled={
                                                                                                    selectedProduct.market_status ==
                                                                                                    'sold'
                                                                                                }
                                                                                            >
                                                                                                {selectedProduct.market_status ==
                                                                                                'sold'
                                                                                                    ? 'Lot Sold'
                                                                                                    : `Submit Offer`}
                                                                                            </PrimaryButton>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : (
                                                                                    ''
                                                                                )}

                                                                                {global
                                                                                    .pluginConfiguration
                                                                                    ?.make_an_offer
                                                                                    ?.enable &&
                                                                                selectedProduct?.make_an_offer_io &&
                                                                                selectedProduct?.auction &&
                                                                                !selectedProduct?.buynow &&
                                                                                upcomming &&
                                                                                selectedProduct?.user_id !=
                                                                                    user?.id ? (
                                                                                    <div className="row">
                                                                                        {mapData(
                                                                                            make_an_offer,
                                                                                        )}
                                                                                        <div className="col-sm-6 col-12">
                                                                                            <PrimaryButton
                                                                                                onClick={(
                                                                                                    e,
                                                                                                ) =>
                                                                                                    selectedProduct.market_status ==
                                                                                                    'open'
                                                                                                        ? make_formik.handleSubmit()
                                                                                                        : ''
                                                                                                }
                                                                                                disabled={
                                                                                                    selectedProduct.market_status ==
                                                                                                    'sold'
                                                                                                }
                                                                                            >
                                                                                                {selectedProduct.market_status ==
                                                                                                'sold'
                                                                                                    ? 'Lot Sold'
                                                                                                    : `Submit Offer`}
                                                                                            </PrimaryButton>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {global?.storeConfigration
                                                                    ?.qty_input_slider?.value !=
                                                                1 ? (
                                                                    <PrimaryButton
                                                                        label="Login to continue"
                                                                        btnSize="small"
                                                                        onClick={() =>
                                                                            handleRedirectInternal(
                                                                                history,
                                                                                'login',
                                                                            )
                                                                        }
                                                                    />
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <Tabs
                                            value={value}
                                            onChange={pvTabsChange}
                                            aria-label="PV tabs"
                                        >
                                            <Tab label="Description" {...a11yProps(0)} />
                                            <Tab label="Transaction Details" {...a11yProps(1)} />
                                            <Tab label="Disclaimer" {...a11yProps(2)} />
                                        </Tabs>
                                        <TabPanel value={value} index={0}>
                                            <p
                                                key={selectedProduct.id}
                                                dangerouslySetInnerHTML={{
                                                    __html: selectedProduct.description,
                                                }}
                                            ></p>
                                        </TabPanel>
                                        <TabPanel value={value} index={1}>
                                            <p
                                                key={selectedProduct.id}
                                                dangerouslySetInnerHTML={{
                                                    __html: auctionData.store_comment_four,
                                                }}
                                            ></p>
                                        </TabPanel>
                                        <TabPanel value={value} index={2}>
                                            <p
                                                key={selectedProduct.id}
                                                dangerouslySetInnerHTML={{
                                                    __html: auctionData.whole_comment_two,
                                                }}
                                            ></p>
                                        </TabPanel>
                                    </div>

                                    {global?.pluginConfiguration?.advertisement?.enable == 1 ? (
                                        <div className="siteAds adsProd">
                                            {isMobile
                                                ? prodAds?.mobProdBottom[
                                                      activeProdAdsIndex?.mobProdBottom
                                                  ]
                                                : prodAds?.deskProdBottom[
                                                      activeProdAdsIndex?.deskProdBottom
                                                  ]}
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <AskQuestion
                                open={askQuestion}
                                selectedProduct={selectedProduct}
                                handleClose={toggleAskQuestion}
                            />
                            <Bidhistory
                                modaltitle="Bid history"
                                open={Object.keys(historyData).length !== 0}
                                value={historyData}
                                handleClose={() => setHistoryData({})}
                            />
                            {user && global.pluginConfiguration?.messages?.enable == 1 && (
                                <MessagePlugin product={selectedProduct} />
                            )}
                            <div className="similar-items-container">
                                <h2 className="similar-items-head">Similar items</h2>
                                <Tabs value={value1} onChange={pvTabsChange1} aria-label="PV tabs">
                                    <Tab label="Closing Soon" {...a11yProps(0)} />
                                    <Tab label="Most Viewed" {...a11yProps(1)} />
                                </Tabs>
                                <TabPanel value={value1} index={0}>
                                    {subLotData.length > 0 ? (
                                        <LotListProduct
                                            listview={false}
                                            searchResult={subLotData}
                                            className="liquidationBuyCard"
                                            cardType={'grid'}
                                            setViewProduct={setSubLotData}
                                        />
                                    ) : (
                                        <p>No Items Found!</p>
                                    )}
                                </TabPanel>
                                <TabPanel value={value1} index={1}>
                                    {subLotData.length > 0 ? (
                                        <LotListProduct
                                            listview={false}
                                            searchResult={subLotData}
                                            className="liquidationBuyCard"
                                            cardType={'grid'}
                                            setViewProduct={setSubLotData}
                                        />
                                    ) : (
                                        <p>No Items Found!</p>
                                    )}
                                </TabPanel>
                            </div>
                        </div>
                    ) : (
                        <div className="container pt-5">
                            <NoRecordsFound />
                        </div>
                    )}
                </>
            )}
        </>
    )
    return viewPage ? (
        render
    ) : (
        <Drawer
            className="rightDrawer productViewDrawer"
            anchor={'right'}
            open={selectedLot}
            onClose={handleClose}
        >
            {render}
        </Drawer>
    )
}

export default SlidePanel
