import React, { useState, useContext, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import './MultisellerListCard.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import FavouriteCheckbox from '../../Product/components/atoms/FavoriteCheckbox'
import Timer from '../../Product/common/timer'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import {
    dateFormatFront,
    handleRedirectInternal,
    removeHTMLTags,
} from '../../Product/common/components'
import { currencyFormat, sellerNameFormat } from '../../Product/common/components'
import { Button, Tooltip, Popover, useMediaQuery } from '@material-ui/core'
import Bidding from '../../Product/components/molecules/Bidding/BiddingItem'
import Biddingforwardandreverse from '../../Product/components/molecules/Bidding/biddingforwardandreverse'
import moment from 'moment-timezone'
import AddToCalendar from '../../Product/components/molecules/AddToCalendar'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import AuthContext from '../../Product/context/auth/authContext'
import CurrencyConverterFormat from '../../utils/CommonFunctionality/CurrencyConverter/CurrencyConverterFormat'
import CartContext from '../../Product/context/cart/cartContext'
import Facebook from '../../utils/CommonFunctionality/Share/Facebook'
import Whatsapp from '../../utils/CommonFunctionality/Share/Whatsapp'
import Twitter from '../../utils/CommonFunctionality/Share/Twitter'
import Email from '../../utils/CommonFunctionality/Share/Email'
import Pinterest from '../../utils/CommonFunctionality/Share/Pinterest'
import Copy from '../../utils/CommonFunctionality/Share/Copy'
import ReadMore from '../ReadMore'
import { Rating } from '@material-ui/lab'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import buyerContext from '../../Product/context/buyer/buyerContext'

const LotsListCard = ({
    data,
    details,
    isFrame,
    lotData,
    type,
    handleClick,
    bidBtnLabel,
    theme,
    bidStatus,
    timerTheme,
    // lotCount,
    auction_and_buynow,
    className,
    cardTheme,
    standalonePage,
    card_theme,
    auctionTimer,
    viewProduct,
    setViewProduct,
    lotDetails,
    is_registered,
    setAlert,
    private_auction,
    fromSearch,
    currencyType,
    noTimer,
    auction_city,
}) => {
    const addDefaultSrc = (e) => {
        e.target.src = `${global?.storeDetails?.logoValue}`
        e.target.className = `gridImage default`
    }
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const { isAuthenticated, user } = authContext
    const cartContext = useContext(CartContext)
    let { bidConfirm } = useContext(buyerContext)
    const [selectedProduct, setSelectedProduct] = useState({})
    const { addToCart, getUserCart, removeFromCart, responseStatus, buyer_cartitems } = cartContext

    const [active, setActive] = useState(false)
    const [bidText, setBidText] = useState('Bid Now')
    const [expanded, setExpanded] = useState(false)
    const handleExpand = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }
    const getBidText = (data) => {
        if (
            isAuthenticated &&
            (data?.latestbid_user != null || data?.highest_bidder_user_id != null) &&
            user?.id != undefined
        ) {
            if (data?.auction) {
                if (
                    (data?.latestbid_user == user?.id ||
                        data?.highest_bidder_user_id == user?.id) &&
                    data?.wprice >= data?.rprice
                ) {
                    if (data?.market_status == 'open') {
                        setBidText('Winning')
                    } else if (data?.market_status == 'sold') {
                        setBidText('Won')
                    }
                } else if (
                    data?.latestbid_user != user?.id &&
                    (data?.currentUserBidAmount || data?.bid_or_not || data?.cbidnot)
                ) {
                    if (data?.market_status == 'open') {
                        setBidText('Outbid')
                    } else if (data?.market_status == 'sold') {
                        setBidText('Lost')
                    }
                } else {
                    setBidText('Bid Now')
                }
            }
        } else {
            setBidText('Bid Now')
        }
    }
    useEffect(() => {
        getBidText(lotData)
    }, [lotData])
    useEffect(() => {
        if (buyer_cartitems.results) {
            if (lotData?.id) {
                let position = _.findIndex(buyer_cartitems.results, {
                    project_id: lotData?.id,
                })
                position === -1 ? setActive(false) : setActive(true)
            }
        }
    }, [buyer_cartitems.results, lotData?.id])

    const isHyperMicroTheme = document.querySelector('body').classList.contains('hyperMicro')
    const [shareAnchorEl, setShareAnchorEl] = useState(null)
    const handleShareClick = (event) => {
        setShareAnchorEl(event.currentTarget)
    }

    const handleShareClose = () => {
        setShareAnchorEl(null)
    }

    const openshare = Boolean(shareAnchorEl)
    const id = open ? 'simple-popover' : undefined

    const handleCart = async (id) => {
        if (active) {
            removeFromCart({ id })
        } else addToCart({ id: lotData.id, qty: lotData.qty })
    }

    const hanlderedirection = (id) => {
        if (parseInt(is_registered) == 0 && parseInt(private_auction) == 1) {
            setAlert('Please register auction and continue!', 'error')
            window.scrollTo(0, 0)
        } else {
            history.push({
                pathname: `/productView/${id}`,
                state: { lotid: lotDetails },
            })
        }
    }

    const checkThemeClassName = document.body.className == 'liquidationTwo primeBids'

    const bidsubmit = (data) => {
        bidConfirm({
            auction_io: 1,
            bid_increment: data.incrementamt,
            email: user?.email,
            first_name: user?.first_name,
            hard_bid: '1',
            id: data.id,
            last_name: user?.last_name,
            wsprice: data.next_bid,
        })
    }

    const isDesktop = useMediaQuery('(max-width:1400px)')

    return type === 'lot' ? (
        <>
            {lotData ? (
                <div
                    className={`multisellerListCard lotCard ${className}${
                        bidText === 'Winning' && 'winningcard'
                    } 
                ${bidText === 'Outbid' && 'losingcard'}`}
                >
                    <div className="grid-img-wrap">
                        {auction_city ? (
                            <div className="grid-loc">
                                <img src="/lots-images/loc.svg" alt="" />
                                {auction_city}, TX
                            </div>
                        ) : (
                            ''
                        )}
                        {global?.storeConfigration?.sold_status?.value == '1' ? (
                            <></>
                        ) : (
                            <>
                                {lotData.market_status === 'sold' && (
                                    <div className="soldWrapper">
                                        <p>SOLD</p>
                                    </div>
                                )}
                                {lotData.market_status === 'closed' && (
                                    <div className="soldWrapper">
                                        <p>UNSOLD</p>
                                    </div>
                                )}
                            </>
                        )}
                        <LazyLoadImage
                            effect="blur"
                            placeholderSrc="/assets/svg/imageLoading.svg"
                            height="100%"
                            width="100%"
                            src={`${
                                lotData.content_head1 === '0' || lotData.store_id === 0
                                    ? process.env.REACT_APP_BASE_URL + 'uploads/product/'
                                    : process.env.REACT_APP_PRODUCT_IMAGE_URL
                            }${
                                global.storeConfigration?.image_compression?.value == 1
                                    ? 'thumb_'
                                    : ''
                            }${
                                (lotData?.lotImages?.length && lotData?.lotImages[0]?.file_name) ||
                                lotData.file_name ||
                                lotData.avatar_vc
                            }`}
                            // onClick={() =>
                            //     standalonePage
                            //         ? handleRedirectInternal(history, `productView/${lotData.id}`)
                            //         : handleClick(lotData.id)
                            // }
                            onClick={() =>
                                global.storeDetails?.theme === 9
                                    ? hanlderedirection(lotData.id)
                                    : standalonePage
                                    ? handleRedirectInternal(history, `productView/${lotData.id}`)
                                    : handleClick(lotData.id)
                            }
                            className="gridImage"
                            onError={addDefaultSrc}
                        />

                        {isAuthenticated &&
                            (bidText == 'Winning' ||
                                bidText == 'Won' ||
                                bidText == 'Outbid' ||
                                bidText == 'Lost') && (
                                <div
                                    className={`${isAuthenticated && 'gridItemAct bid-now-wrap'} ${
                                        isAuthenticated &&
                                        (bidText == 'Winning' || bidText == 'Won') &&
                                        'winBtnWrpr'
                                    } ${
                                        isAuthenticated &&
                                        (bidText == 'Outbid' || bidText == 'Lost') &&
                                        'outbidBtnWrpr'
                                    }`}
                                >
                                    <PrimaryButton
                                        disabled={lotData.market_status == 'sold'}
                                        name={lotData.id}
                                        label={bidText}
                                        btnColor={lotData.latestbid_user == user?.id && 'success'}
                                    />
                                </div>
                            )}
                        {lotData.market_status == 'open' ? (
                            <FavouriteCheckbox
                                watchlisted={
                                    lotData.watchlistid || lotData.watchlisted || lotData.isFav
                                }
                                project_id={lotData.id}
                                from={'card'}
                                viewProduct={viewProduct}
                                setViewProduct={setViewProduct}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="mslDesc">
                        <h3
                            className="listTitle"
                            dangerouslySetInnerHTML={{
                                __html: lotData.title,
                            }}
                            onClick={() =>
                                standalonePage
                                    ? handleRedirectInternal(history, `productView/${lotData.id}`)
                                    : handleClick(lotData.id)
                            }
                        />
                        <div className="ratingLiqThree d-flex justify-content-between">
                            {/* Condition:{' '} */}
                            <div className="d-flex justify-content-between w-100 flex-wrap ">
                                <div
                                    style={{ gap: '5px' }}
                                    className="d-flex justify-content-start w-100 flex-wrap mb-2"
                                >
                                    {lotData.auctionnotes.length
                                        ? lotData.auctionnotes != (null || undefined) &&
                                          lotData.auctionnotes?.split(',')?.map((val, index) => (
                                              <div
                                                  className="conditionLiq loa-condition"
                                                  key={index}
                                              >
                                                  <p className="m-0 cursorDecoy">{val}</p>
                                              </div>
                                          ))
                                        : null}
                                </div>
                                <div className="glot-id">
                                    {fromSearch ||
                                    global.storeConfigration?.own_seller?.value == '1' ? (
                                        <h6>
                                            {global.storeDetails.theme == 19 ? (
                                                <>Lot #{lotData.lot_number}</>
                                            ) : (
                                                <>Lot Id# {lotData.category_id}</>
                                            )}
                                        </h6>
                                    ) : (
                                        <h6>Auction Id# {lotData.lotof}</h6>
                                    )}
                                </div>
                            </div>
                            {/* <Rating
                                name="read-only"
                                value={
                                    global.storeDetails.theme == 19
                                        ? lotData.auctionnotes
                                              .trim()
                                              .toLowerCase()
                                              .includes('rating 5')
                                            ? 5
                                            : lotData.auctionnotes
                                                  .trim()
                                                  .toLowerCase()
                                                  .includes('rating 4')
                                            ? 4
                                            : lotData.auctionnotes
                                                  .trim()
                                                  .toLowerCase()
                                                  .includes('rating 3')
                                            ? 3
                                            : lotData.auctionnotes
                                                  .trim()
                                                  .toLowerCase()
                                                  .includes('rating 2')
                                            ? 2
                                            : 1
                                        : 4
                                }
                                precision={0.5}
                                readOnly
                            /> */}
                        </div>

                        {lotData.condition_field && global.storeDetails?.theme !== 19 ? (
                            <div className="conditionLiq loa-condition">
                                <p className="m-0 cursorDecoy">{lotData.condition_field}</p>
                            </div>
                        ) : null}
                        <div className="lv-bid-details">
                            <h6 className="galleryPrice actnPriceVlue nowrap d-flex">
                                <span>Current Bid: </span>
                                <span className="d-flex align-items-center">
                                    <span className="prcTxtVal">
                                        {currencyFormat(
                                            parseFloat(lotData.wprice).toFixed(2),
                                            currencyType,
                                        )}
                                    </span>
                                </span>
                            </h6>
                            {cardTheme !== 'carAuction' && global.storeDetails?.theme !== 18 ? (
                                <>
                                    {!noTimer && lotData.date_closed && (
                                        <div
                                            className={`gcTimer primeTmCrd hoTimer ${
                                                lotData.buynow === 1 && lotData.auction === 0
                                                    ? 'noBackDrop'
                                                    : ''
                                            }`}
                                        >
                                            {/*eslint-disable*/}
                                            {(lotData.market_status === 'open' &&
                                                lotData.auction) ||
                                            (lotData.market_status === 'open' &&
                                                auction_and_buynow) ? (
                                                <Timer
                                                    date_added={lotData.date_added}
                                                    date_closed={lotData.date_closed}
                                                    withText={1}
                                                    endText={'Time Remaining' + ': '}
                                                    startText={'Starts in' + ': '}
                                                    theme={timerTheme}
                                                    icon={false}
                                                    product_id={lotData.id}
                                                    viewProduct={viewProduct}
                                                    setViewProduct={setViewProduct}
                                                />
                                            ) : (
                                                <>
                                                    {lotData.buynow === 0 ? (
                                                        <span className="closedText">
                                                            Auction Closed
                                                        </span>
                                                    ) : (
                                                        <div className="closedText"></div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    )}
                                </>
                            ) : (
                                ''
                            )}
                            {lotData.next_bid ? (
                                <h6 className="galleryPrice actnPriceVlue nowrap d-flex">
                                    <span>Min Bid: </span>
                                    <span className="d-flex align-items-center">
                                        <span className="prcTxtVal">
                                            {currencyFormat(
                                                parseFloat(lotData.next_bid).toFixed(2),
                                                currencyType,
                                            )}
                                        </span>
                                    </span>
                                </h6>
                            ) : (
                                ''
                            )}
                            <h6 className="galleryPrice actnPriceVlue nowrap d-flex">
                                <span>MSRP: </span>
                                <span className="d-flex align-items-center">
                                    <span className="prcTxtVal">
                                        {' '}
                                        {currencyFormat(
                                            parseFloat(lotData.mprice).toFixed(2),
                                            currencyType,
                                        )}
                                    </span>
                                </span>
                            </h6>
                            {lotData.incrementamt ? (
                                <h6 className="galleryPrice actnPriceVlue nowrap d-flex">
                                    <span>Bid Increment: </span>
                                    <span className="d-flex align-items-center">
                                        <span className="prcTxtVal">
                                            {' '}
                                            {currencyFormat(
                                                parseFloat(lotData.incrementamt).toFixed(2),
                                                currencyType,
                                            )}
                                        </span>
                                    </span>
                                </h6>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="lv-bid-btns">
                            {lotData.id !== undefined ? (
                                type !== 'buynow' && (
                                    <div className="gridItemAct bid-now-wrap">
                                        <PrimaryButton
                                            disabled={lotData.market_status == 'sold'}
                                            name={lotData.id}
                                            onClick={() =>
                                                isAuthenticated
                                                    ? handleClick(lotData.id)
                                                    : handleRedirectInternal(history, 'login')
                                            }
                                            label={
                                                window.location.pathname.includes('search') ||
                                                type === 'Auction' ||
                                                lotData.auction
                                                    ? (type === 'buynow' || lotData.buynow) &&
                                                      (!lotData.auction ||
                                                          window.location.href.includes('/gallery'))
                                                        ? isAuthenticated
                                                            ? 'Buy Now'
                                                            : 'Login To Buy Now'
                                                        : isAuthenticated
                                                        ? 'Bid Now'
                                                        : 'Login To Bid'
                                                    : isAuthenticated
                                                    ? 'Buy Now'
                                                    : 'Login To Buy Now'
                                            }
                                            btnColor={
                                                lotData.latestbid_user == user?.id && 'success'
                                            }
                                        />

                                        {lotData.auction && isAuthenticated ? (
                                            type === 'buynow' && !lotData.auction ? null : (
                                                <>
                                                    {global.storeConfigration?.hard_bid?.value ==
                                                        '1' && (
                                                        <div className="lots-bidding-view">
                                                            <Bidding
                                                                data={lotData}
                                                                hard="1"
                                                                shortText="Submit Your Bid"
                                                                enableNextBid={true}
                                                                nextbidText={`Quick Bid ${
                                                                    lotData?.next_bid
                                                                        ? currencyFormat(
                                                                              parseFloat(
                                                                                  lotData?.next_bid
                                                                                      ? lotData?.next_bid
                                                                                      : 0,
                                                                              ).toFixed(2),
                                                                          )
                                                                        : ''
                                                                }`}
                                                                hideBid={true}
                                                                removePopup={true}
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            )
                                        ) : null}

                                        {cardTheme !== 'carAuction' &&
                                        global?.storeConfigration?.qty_input_slider?.value != 1 &&
                                        lotData.market_status == 'open' ? (
                                            <>
                                                {type != 'buynow' && type == 'Auction'
                                                    ? ''
                                                    : isAuthenticated &&
                                                      parseInt(lotData.wprice) <=
                                                          parseInt(lotData.bprice) && (
                                                          <Button
                                                              className={`cartBtn ${
                                                                  active ? 'active' : ''
                                                              } `}
                                                              onClick={() => handleCart(lotData.id)}
                                                              disabled={
                                                                  lotData.buynow == 0 ||
                                                                  user?.status == 'suspended'
                                                              }
                                                          >
                                                              <span className="material-icons-outlined">
                                                                  {active
                                                                      ? 'shopping_cart_checkout'
                                                                      : 'add_shopping_cart'}
                                                              </span>
                                                          </Button>
                                                      )}
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                )
                            ) : (
                                <></>
                            )}
                        </div>
                        <Accordion
                            expanded={expanded === `panel${lotData.category_id}`}
                            onChange={handleExpand(`panel${lotData.category_id}`)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${lotData.category_id}a-content`}
                                id={`panel${lotData.category_id}a-header`}
                            >
                                <h6 className="font-weight-bold">Description</h6>
                            </AccordionSummary>
                            <AccordionDetails>
                                <p
                                    className="listDesc"
                                    dangerouslySetInnerHTML={{ __html: lotData.description }}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            ) : null}
        </>
    ) : (
        <>
            {data ? (
                <div className="multisellerListCard asdasd">
                    {data.file_name ? (
                        <div className="grid-img-wrap">
                            <Link to={details} target={isFrame ? '_blank' : ''}>
                                <LazyLoadImage
                                    effect="blur"
                                    placeholderSrc="/assets/svg/imageLoading.svg"
                                    height="100%"
                                    width="100%"
                                    src={`${
                                        data.content_head1 === '0' || data.store_id === 0
                                            ? process.env.REACT_APP_BASE_URL + 'uploads/product/'
                                            : process.env.REACT_APP_PRODUCT_IMAGE_URL
                                    }${
                                        global.storeConfigration?.image_compression?.value == 1
                                            ? 'thumb_'
                                            : ''
                                    }${
                                        (lotImages?.length && lotImages[0]?.file_name) ||
                                        (data?.lotImages?.length &&
                                            data?.lotImages[0]?.file_name) ||
                                        data.file_name ||
                                        data.avatar_vc
                                    }`}
                                    className="gridImage"
                                    onError={addDefaultSrc}
                                    onClick={() =>
                                        global.storeDetails?.theme === 9
                                            ? hanlderedirection(lotData.id)
                                            : standalonePage
                                            ? handleRedirectInternal(
                                                  history,
                                                  `productView/${lotData.id}`,
                                              )
                                            : handleClick(lotData.id)
                                    }
                                />
                                <span className={`badge ${data.con_check ? 'live' : 'timed'}`}>
                                    {data.con_check ? 'Live' : 'Timed'}
                                </span>
                            </Link>
                            {/* {lotCount ? (
                                <span className="lotCount">
                                    <span className="material-icons">view_carousel</span>
                                    {lotCount} lot{lotCount > 1 && 's'}
                                </span>
                            ) : (
                                ''
                            )} */}
                        </div>
                    ) : (
                        <Skeleton height={220} width={720} />
                    )}

                    <div className="mslDesc">
                        <Link to={details} target={isFrame ? '_blank' : ''}>
                            <h3
                                className="listTitle"
                                dangerouslySetInnerHTML={{
                                    __html: data.title,
                                }}
                            />
                        </Link>

                        <label className="mb-1">Description:</label>
                        {checkThemeClassName ? (
                            <>
                                <ReadMore data={data.description} className="listDesc" limit={30} />
                            </>
                        ) : (
                            <>
                                <p
                                    className="listDesc"
                                    dangerouslySetInnerHTML={{ __html: data.description }}
                                />
                            </>
                        )}

                        <div className="lotImagesGrid">
                            {data.lot_images &&
                                data.lot_images.map((val, index) => (
                                    <>
                                        <LazyLoadImage
                                            effect="blur"
                                            placeholderSrc="/assets/svg/imageLoading.svg"
                                            height="100%"
                                            width="100%"
                                            src={`${
                                                data.store_id === 0
                                                    ? process.env.REACT_APP_BASE_URL +
                                                      'uploads/product/'
                                                    : process.env.REACT_APP_PRODUCT_IMAGE_URL
                                            }${
                                                global.storeConfigration?.image_compression
                                                    ?.value == 1
                                                    ? 'thumb_'
                                                    : ''
                                            }${val?.file_name}`}
                                            key={index}
                                            onError={addDefaultSrc}
                                        />
                                    </>
                                ))}
                        </div>
                    </div>
                    <div className="mslMisc">
                        {card_theme != 'liquidationTwo' && (
                            <>
                                {data.market_status === 'open' ? (
                                    <Timer
                                        date_added={data.date_added}
                                        date_closed={data.date_closed}
                                        withText={1}
                                        endText={'Time left' + ':'}
                                        startText={'Starts in' + ':'}
                                        theme={theme ? '' : 'multiseller'}
                                        icon={true}
                                    />
                                ) : (
                                    // <span className="closedText">Auction Closed</span>
                                    <div className="timerWrapper">
                                        <span className="material-icons-outlined">timer</span>
                                        <h6 className="timeLabel">Auction closed</h6>
                                    </div>
                                )}
                            </>
                        )}
                        {/* <p>Lots : {data.lotCount}</p> */}
                        <PrimaryButton
                            onClick={() =>
                                handleRedirectInternal(
                                    history,
                                    parseInt(data?.event_enable ? data.event_enable : 0) == 1
                                        ? `eventAuction/${data.id}`
                                        : details,
                                )
                            }
                        >
                            {card_theme === 'liquidationTwo'
                                ? 'EXPLORE AUCTION'
                                : 'Browse Auction Lots'}
                        </PrimaryButton>
                        {card_theme === 'liquidationTwo' && (
                            <div className="strsEndsDat">
                                {global?.storeConfigration?.timer_string_change?.value ? (
                                    <p className="showEnds">
                                        Ends on {dateFormatFront(data.date_closed)}
                                    </p>
                                ) : (
                                    <p className="showEnds">
                                        Ends {dateFormatFront(data.date_closed)}
                                    </p>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            ) : null}
        </>
    )
}

export default LotsListCard
